import React, { useEffect } from "react";
import axios from "axios";

const Create = () => {
  const createRoom = async () => {
    await axios.post(
      "https://rlvidnow.com:5443/LiveApp/rest/v2/broadcasts/conference-rooms",
      {
        roomId: new Date().getTime(),
        startDate: 0,
        endDate: 0,
        roomStreamList: [],
      }
    );
  };

  return (
    <div>
      <h1>Create</h1>
      <button onClick={() => createRoom()}>Create Live</button>
    </div>
  );
};

export default Create;

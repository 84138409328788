import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const ShowRoom = () => {
  const [allLiveId, setAllLiveId] = useState([]);
  let { id } = useParams();

  const fetchAllLives = async () => {
    console.log("asd");
    const allLiveId = await axios.get(
      `https://rlvidnow.com:5443/LiveApp/rest/v2/broadcasts/conference-rooms/${id}`
    );

    console.log("**************************allLiveId", allLiveId);
    setAllLiveId(allLiveId.data.roomStreamList);
  };

  const allBroadCaster = async () => {
    const allBroadCasterId = await axios.get(
      "https://rlvidnow.com:5443/LiveApp/rest/v2/broadcasts/list/0/20"
    );
    setAllLiveId(allBroadCasterId.data);
    console.log("allBroadCasterId", allBroadCasterId.data);
  };

  useEffect(() => {
    // fetchAllLives();
    allBroadCaster();
  }, []);
  return (
    <div>
      <h1>Show Room</h1>
      <h2>{id}</h2>

      <Link to={`/joinlive/${id}`}>Join</Link>
      {allLiveId.map((liveId, index) => {
        return (
          <div key={index}>
            <iframe
              width="560"
              height="315"
              src={`https://rlvidnow.com:5443/LiveApp/play.html?name=${liveId.streamId}`}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        );
      })}
    </div>
  );
};

export default ShowRoom;

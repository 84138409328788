import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 50px;
  background-color: #ff0000;
  justify-content: space-between;
`;

const List = (props) => {
  const [allLive, setAllLive] = useState([]);

  const fetchAllLive = async () => {
    const allList = await axios.get(
      "https://rlvidnow.com:5443/LiveApp/rest/v2/broadcasts/conference-rooms/list/0/20"
    );
    console.log("allList", allList);

    setAllLive(allList.data);
  };

  const removeroom = async (roomId) => {
    const removeId = await axios.delete(
      `https://rlvidnow.com:5443/LiveApp/rest/v2/broadcasts/conference-rooms/${roomId}`
    );
    fetchAllLive();
  };

  useEffect(() => {
    fetchAllLive();
  }, []);

  console.log("props", props);

  return (
    <div>
      <h1>List</h1>
      {allLive.map((live) => {
        return (
          <Container key={live.roomId}>
            <Link to={`/showroom/${live.roomId}`}>
              <p>{live.roomId}</p>
            </Link>
            <button onClick={() => removeroom(live.roomId)}>remove room</button>
          </Container>
        );
      })}
    </div>
  );
};
export default List;
